import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

const adapter = createEntityAdapter();

const initialState = adapter.getInitialState({
  loading: false,
});

const dailyActivityReportsSlice = createSlice({
  name: "dailyActivityReports",
  initialState,
});

const { reducer } = dailyActivityReportsSlice;

export default reducer;
