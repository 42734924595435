import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

const adapter = createEntityAdapter();

const incidentReportsSlice = createSlice({
  name: "incidentReports",
  initialState: adapter.getInitialState(),
});

const { reducer } = incidentReportsSlice;

export default reducer;
