import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import { useSelector } from "react-redux";

import selectDarkModeEnabled from "../../store/settings/selectors/selectDarkModeEnabled";

const ThemedCircularProgress = (props) => {
  const darkModeEnabled = useSelector(selectDarkModeEnabled);
  return (
    <CircularProgress
      {...props}
      color={darkModeEnabled ? "inherit" : "primary"}
    />
  );
};

export default ThemedCircularProgress;
