import { createSelector } from "@reduxjs/toolkit";

import selectEntities from "../selectEntities";

const selectEntity = createSelector(
  [selectEntities, (_, id) => id],
  (entities, id) => entities[id]
);

export default selectEntity;
