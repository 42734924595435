import Auth from "@aws-amplify/auth";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import BrightnessMediumIcon from "@material-ui/icons/BrightnessMedium";
import HomeIcon from "@material-ui/icons/Home";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Separator from "../Separator";
import { toggleDarkMode } from "../../store/settings";
import selectDarkModeEnabled from "../../store/settings/selectors/selectDarkModeEnabled";

const useStyles = makeStyles((theme) => ({
  homeButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flex: 1,
  },
}));

const DefaultAppBar = ({ children, title }) => {
  const classes = useStyles();
  const darkModeEnabled = useSelector(selectDarkModeEnabled);
  const dispatch = useDispatch();
  return (
    <AppBar color={darkModeEnabled ? "inherit" : "primary"} position="relative">
      <Toolbar>
        <IconButton
          className={classes.homeButton}
          color="inherit"
          component={Link}
          edge="start"
          to="/"
        >
          <HomeIcon />
        </IconButton>
        <Typography className={classes.title} noWrap variant="h6">
          {title}
        </Typography>
        <IconButton color="inherit" onClick={() => dispatch(toggleDarkMode())}>
          <BrightnessMediumIcon />
        </IconButton>
        <Separator horizontal />
        <Button
          color="inherit"
          onClick={async () => {
            try {
              await Auth.signOut();
              window.location.reload();
            } catch (error) {
              console.warn(error);
            }
          }}
        >
          Sign out
        </Button>
      </Toolbar>
      {children}
    </AppBar>
  );
};

DefaultAppBar.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
};

DefaultAppBar.defaultProps = {
  children: null,
};

export default DefaultAppBar;
