import gql from "graphql-tag";

const propertyFragment = gql`
  fragment propertyFragment on Property {
    variant
    patrolWindows {
      minsToStart
      minsToEnd
      darsRequired
    }
    geofence
    place {
      coordinates {
        lat
        lng
      }
      formattedAddress
      addressComponents {
        city
        state
        county
        country
        zipCode
        subpremise
        streetName
        neighborhood
        streetNumber
        zipCodeSuffix
      }
      timezone
    }
    isDeleted
    adminNote
    createdAt
    updatedAt
    propertyId
    instanceId
    instancedAt
    displayName
    cardinal {
      time {
        start
        end
      }
      indefiniteStart
      note
      indefinite
    }
    serviceProviderId
    serviceProviderNote
  }
`;

export default propertyFragment;
