import query from "../queryApprovedIncidentReports/graphql";
import client from "../../client";

const watchQueryApprovedIncidentReports = ({ propertyId, first, after }) => {
  return client.watchQuery({
    query,
    variables: {
      propertyId,
      first,
      after,
    },
  });
};

export default watchQueryApprovedIncidentReports;
