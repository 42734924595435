import gql from "graphql-tag";

import clientFragment from "../../../fragments/client";

const getClient = gql`
  query getClient($id: ID!) {
    getClient(id: $id) {
      ...clientFragment
    }
  }
  ${clientFragment}
`;

export default getClient;
