import query from "../queryApprovedDailyActivityReports/graphql";
import client from "../../client";

const watchQueryApprovedDailyActivityReports = ({
  propertyId,
  first,
  after,
}) => {
  return client.watchQuery({
    query,
    variables: {
      propertyId,
      first,
      after,
    },
  });
};

export default watchQueryApprovedDailyActivityReports;
