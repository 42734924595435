import query from "./graphql";
import client from "../../client";
import generateErrorMessage from "../../utils/generateErrorMessage";

const getProperty = async (id) => {
  try {
    const { data } = await client.query({
      query,
      variables: {
        propertyId: id,
      },
      fetchPolicy: "no-cache",
    });
    return data.getProperty;
  } catch (errors) {
    throw generateErrorMessage("getProperty", errors);
  }
};

export default getProperty;
