import PropTypes from "prop-types";

const TabPanel = ({ children, index, value }) => {
  return value === index && children;
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default TabPanel;
