import Storage from "@aws-amplify/storage";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import DefaultAppBar from "../../components/DefaultAppBar";
import PropertyListItem from "../../components/PropertyListItem";
import Separator from "../../components/Separator";
import config from "../../config";
import selectUser from "../../store/auth/selectors/selectUser";
import selectServiceProvider from "../../store/serviceProvider/selectors/selectServiceProvider";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
  },
  gridContainer: {
    height: "100%",
  },
  logo: {
    width: "50%",
  },
  logoContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  text: {
    paddingLeft: 16,
    paddingRight: 16,
  },
}));

const Home = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const serviceProvider = useSelector(selectServiceProvider);
  const user = useSelector(selectUser);
  const [logoUrl, setLogoUrl] = useState(null);
  useEffect(() => {
    if (serviceProvider && serviceProvider.logoPath) {
      (async () => {
        try {
          const url = await Storage.vault.get(serviceProvider.logoPath, {
            bucket: config.aws.serviceProviderLogosBucket,
          });
          setLogoUrl(url);
        } catch (error) {
          // Do nothing
        }
      })();
    }
  }, [serviceProvider]);
  return (
    <div className={classes.root}>
      <DefaultAppBar title="Home" />
      <Container
        className={classes.container}
        disableGutters={!matches}
        maxWidth="sm"
      >
        <Separator size="medium" />
        <Paper square={!matches}>
          <Separator size="medium" />
          {Boolean(logoUrl) && (
            <>
              <div className={classes.logoContainer}>
                <img
                  alt="Company logo"
                  className={classes.logo}
                  onError={() => setLogoUrl(null)}
                  src={logoUrl}
                />
              </div>
              <Separator size={4} />
            </>
          )}
          <Typography align="center" variant="h6">
            {serviceProvider.fullName}
          </Typography>
          <Separator size="medium" />
          <Typography align="center" variant="subtitle2">
            {`${user.firstName} ${user.lastName}`}
          </Typography>
          <Typography align="center" variant="body2">
            {user.email}
          </Typography>
          <Separator size="medium" />
          <Typography className={classes.text} variant="overline">
            Select a property
          </Typography>
          <Divider />
          <List>
            {user.propertyIds.map((propertyId) => (
              <PropertyListItem id={propertyId} key={propertyId} />
            ))}
          </List>
        </Paper>
        <Separator size="medium" />
      </Container>
    </div>
  );
};

export default Home;
