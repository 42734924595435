import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import moment from "moment-timezone";
import React, { useCallback, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";

import getIncidentReport from "../../api/functions/getIncidentReport";
import DefaultAppBar from "../../components/DefaultAppBar";
import Error from "../../components/Error";
import MediaImage from "../../components/MediaImage";
import Separator from "../../components/Separator";
import ThemedCircularProgress from "../../components/ThemedCircularProgress";
import momentFormats from "../../constants/momentFormats";
import * as subjectConstants from "../../constants/subject";
import * as vehicleConstants from "../../constants/vehicle";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
  },
  contentCenteredContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    overflow: "hidden",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
}));

const IncidentReport = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const {
    params: { id },
  } = match;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [data, setData] = useState({
    loading: false,
    error: null,
    incidentReport: null,
  });
  const runGetIncidentReport = useCallback(async () => {
    setData({ loading: false, error: null, incidentReport: null });
    try {
      const incidentReport = await getIncidentReport(id);
      setData((prevState) => ({
        ...prevState,
        loading: false,
        incidentReport,
      }));
    } catch (error) {
      setData((prevState) => ({ ...prevState, loading: false, error }));
    }
  }, [id]);
  useEffect(() => {
    runGetIncidentReport();
  }, [runGetIncidentReport]);
  const { loading, error, incidentReport } = data;
  return (
    <div className={classes.root}>
      <DefaultAppBar title="Incident Report" />
      <Container
        className={classes.container}
        disableGutters={!matches}
        maxWidth="sm"
      >
        {loading ? (
          <>
            <Separator size={70} />
            <div className={classes.contentCenteredContainer}>
              <ThemedCircularProgress />
            </div>
          </>
        ) : error ? (
          <>
            <Separator size={70} />
            <Error
              message="Sorry. An error occurred while loading your data."
              onClick={runGetIncidentReport}
            />
          </>
        ) : incidentReport ? (
          <>
            <Separator size="medium" />
            <Paper className={classes.paper} square={!matches}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Separator />
                  <Typography color="textSecondary" variant="h6">
                    General
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    InputProps={{ readOnly: true }}
                    label="Title"
                    multiline
                    value={incidentReport.title}
                    variant="filled"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    InputProps={{ readOnly: true }}
                    label="Report ID"
                    value={incidentReport.incidentId}
                    variant="filled"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    InputProps={{ readOnly: true }}
                    label="Submitted at"
                    value={moment(incidentReport.submitted.at).format(
                      momentFormats.ampmDateTimeFormat
                    )}
                    variant="filled"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    fullWidth
                    InputProps={{ readOnly: true }}
                    label="Started at"
                    value={moment(incidentReport.startTime).format(
                      momentFormats.ampmDateTimeFormat
                    )}
                    variant="filled"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    fullWidth
                    InputProps={{ readOnly: true }}
                    label="Ended at"
                    value={moment(incidentReport.endTime).format(
                      momentFormats.ampmDateTimeFormat
                    )}
                    variant="filled"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    InputProps={{ readOnly: true }}
                    label="Description"
                    multiline
                    value={incidentReport.description}
                    variant="filled"
                  />
                </Grid>
                <Grid item xs={12} />
              </Grid>
            </Paper>
            {incidentReport.policeCase && (
              <>
                <Separator size="medium" />
                <Paper className={classes.paper} square={!matches}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Separator />
                      <Typography color="textSecondary" variant="h6">
                        General
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        fullWidth
                        InputProps={{ readOnly: true }}
                        label="Agency"
                        value={incidentReport.policeCase.agency}
                        variant="filled"
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        fullWidth
                        InputProps={{ readOnly: true }}
                        label="Case #"
                        value={incidentReport.policeCase.number}
                        variant="filled"
                      />
                    </Grid>
                    <Grid item xs={12} />
                  </Grid>
                </Paper>
              </>
            )}
            {Array.isArray(incidentReport.subjects) &&
              incidentReport.subjects.length > 0 && (
                <>
                  <Separator size="medium" />
                  <Paper className={classes.paper} square={!matches}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Separator />
                        <Typography color="textSecondary" variant="h6">
                          Subjects
                        </Typography>
                      </Grid>
                      {incidentReport.subjects.map((subject, index) => (
                        <React.Fragment key={index.toString()}>
                          {index > 0 && (
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Typography variant="overline">{`Subject #${
                              index + 1
                            }`}</Typography>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <TextField
                              fullWidth
                              InputProps={{ readOnly: true }}
                              label="Name"
                              value={subject.name}
                              variant="filled"
                            />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <TextField
                              fullWidth
                              InputProps={{ readOnly: true }}
                              label="Type"
                              value={
                                subject.sex &&
                                subjectConstants.types[subject.type].text
                              }
                              variant="filled"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              InputProps={{ readOnly: true }}
                              label="Description"
                              multiline
                              value={subject.description}
                              variant="filled"
                            />
                          </Grid>
                          <Grid item sm={3} xs={12}>
                            <TextField
                              fullWidth
                              InputProps={{ readOnly: true }}
                              label="Sex"
                              value={
                                subject.sex &&
                                subjectConstants.sexes[subject.sex].text
                              }
                              variant="filled"
                            />
                          </Grid>
                          <Grid item sm={9} xs={12}>
                            <TextField
                              fullWidth
                              InputProps={{ readOnly: true }}
                              label="Address"
                              value={subject.address}
                              variant="filled"
                            />
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <TextField
                              fullWidth
                              InputProps={{ readOnly: true }}
                              label="Ethnicity"
                              value={
                                subject.ethnicity &&
                                subjectConstants.ethnicities[subject.ethnicity]
                                  .text
                              }
                              variant="filled"
                            />
                          </Grid>
                          <Grid item sm={4} xs={6}>
                            <TextField
                              fullWidth
                              InputProps={{ readOnly: true }}
                              label="Race"
                              value={
                                subject.race &&
                                subjectConstants.races[subject.race].text
                              }
                              variant="filled"
                            />
                          </Grid>
                          <Grid item sm={4} xs={6}>
                            <TextField
                              fullWidth
                              InputProps={{ readOnly: true }}
                              label="Phone #"
                              value={subject.phoneNumber}
                              variant="filled"
                            />
                          </Grid>
                          <Grid item sm={4} xs={6}>
                            <TextField
                              fullWidth
                              InputProps={{ readOnly: true }}
                              label="Height"
                              value={subject.height}
                              variant="filled"
                            />
                          </Grid>
                          <Grid item sm={4} xs={6}>
                            <TextField
                              fullWidth
                              InputProps={{ readOnly: true }}
                              label="Weight"
                              value={subject.weight}
                              variant="filled"
                            />
                          </Grid>
                          <Grid item sm={4} xs={6}>
                            <TextField
                              fullWidth
                              InputProps={{ readOnly: true }}
                              label="Age"
                              value={subject.age}
                              variant="filled"
                            />
                          </Grid>
                          <Grid item sm={4} xs={6}>
                            <TextField
                              fullWidth
                              InputProps={{ readOnly: true }}
                              label="Hair color"
                              value={
                                subject.hairColor &&
                                subjectConstants.hairColors[subject.hairColor]
                                  .text
                              }
                              variant="filled"
                            />
                          </Grid>
                          <Grid item sm={4} xs={6}>
                            <TextField
                              fullWidth
                              InputProps={{ readOnly: true }}
                              label="Eye color"
                              value={
                                subject.eyeColor &&
                                subjectConstants.eyeColors[subject.eyeColor]
                                  .text
                              }
                              variant="filled"
                            />
                          </Grid>
                          <Grid item sm={4} xs={6}>
                            <TextField
                              fullWidth
                              InputProps={{ readOnly: true }}
                              label="Date of birth"
                              value={subject.dateOfBirth}
                              variant="filled"
                            />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <TextField
                              fullWidth
                              InputProps={{ readOnly: true }}
                              label="Driver license #"
                              value={
                                subject.driverLicense &&
                                subject.driverLicense.number
                              }
                              variant="filled"
                            />
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <TextField
                              fullWidth
                              InputProps={{ readOnly: true }}
                              label="Driver license state"
                              value={
                                subject.driverLicense &&
                                subject.driverLicense.state
                              }
                              variant="filled"
                            />
                          </Grid>
                        </React.Fragment>
                      ))}
                      <Grid item xs={12} />
                    </Grid>
                  </Paper>
                </>
              )}
            {Array.isArray(incidentReport.vehicles) &&
              incidentReport.vehicles.length > 0 && (
                <>
                  <Separator size="medium" />
                  <Paper className={classes.paper} square={!matches}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Separator />
                        <Typography color="textSecondary" variant="h6">
                          Vehicles
                        </Typography>
                      </Grid>
                      {incidentReport.vehicles.map((vehicle, index) => (
                        <React.Fragment key={index.toString()}>
                          {index > 0 && (
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Typography variant="overline">{`Vehicle #${
                              index + 1
                            }`}</Typography>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <TextField
                              fullWidth
                              InputProps={{ readOnly: true }}
                              label="Registered owner"
                              value={vehicle.registeredOwner}
                              variant="filled"
                            />
                          </Grid>
                          <Grid item sm={3} xs={6}>
                            <TextField
                              fullWidth
                              InputProps={{ readOnly: true }}
                              label="Type"
                              value={
                                vehicle.type &&
                                vehicleConstants.types[vehicle.type].text
                              }
                              variant="filled"
                            />
                          </Grid>
                          <Grid item sm={3} xs={6}>
                            <TextField
                              fullWidth
                              InputProps={{ readOnly: true }}
                              label="Status"
                              value={
                                vehicle.status &&
                                vehicleConstants.statuses[vehicle.status].text
                              }
                              variant="filled"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              InputProps={{ readOnly: true }}
                              label="Description"
                              multiline
                              value={vehicle.description}
                              variant="filled"
                            />
                          </Grid>
                          <Grid item sm={3} xs={6}>
                            <TextField
                              fullWidth
                              InputProps={{ readOnly: true }}
                              label="Make"
                              value={vehicle.make}
                              variant="filled"
                            />
                          </Grid>
                          <Grid item sm={3} xs={6}>
                            <TextField
                              fullWidth
                              InputProps={{ readOnly: true }}
                              label="Model"
                              value={vehicle.model}
                              variant="filled"
                            />
                          </Grid>
                          <Grid item sm={3} xs={6}>
                            <TextField
                              fullWidth
                              InputProps={{ readOnly: true }}
                              label="Year"
                              value={vehicle.year}
                              variant="filled"
                            />
                          </Grid>
                          <Grid item sm={3} xs={6}>
                            <TextField
                              fullWidth
                              InputProps={{ readOnly: true }}
                              label="Color"
                              value={vehicle.color}
                              variant="filled"
                            />
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <TextField
                              fullWidth
                              InputProps={{ readOnly: true }}
                              label="License plate #"
                              value={
                                vehicle.vehicleLicense &&
                                vehicle.vehicleLicense.number
                              }
                              variant="filled"
                            />
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <TextField
                              fullWidth
                              InputProps={{ readOnly: true }}
                              label="License plate state"
                              value={
                                vehicle.vehicleLicense &&
                                vehicle.vehicleLicense.state
                              }
                              variant="filled"
                            />
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <TextField
                              fullWidth
                              InputProps={{ readOnly: true }}
                              label="VIN"
                              value={vehicle.vin}
                              variant="filled"
                            />
                          </Grid>
                        </React.Fragment>
                      ))}
                      <Grid item xs={12} />
                    </Grid>
                  </Paper>
                </>
              )}
            {Array.isArray(incidentReport.attachedMedia) &&
              incidentReport.attachedMedia.length > 0 && (
                <>
                  <Separator size="medium" />
                  <Paper className={classes.paper} square={!matches}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Separator />
                        <Typography color="textSecondary" variant="h6">
                          Media
                        </Typography>
                      </Grid>
                      {incidentReport.attachedMedia.map((media, index) => (
                        <React.Fragment key={media.mediaId}>
                          {index > 0 && (
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <MediaImage media={media} />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              InputProps={{ readOnly: true }}
                              label="Created at"
                              value={moment(media.createdAt).format(
                                momentFormats.ampmDateTimeFormat
                              )}
                              variant="filled"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              InputProps={{ readOnly: true }}
                              label="Description"
                              multiline
                              value={media.description}
                              variant="filled"
                            />
                          </Grid>
                        </React.Fragment>
                      ))}
                      <Grid item xs={12} />
                    </Grid>
                  </Paper>
                </>
              )}
            <Separator size="medium" />
          </>
        ) : (
          <div />
        )}
      </Container>
    </div>
  );
};

export default IncidentReport;
