import Auth from "@aws-amplify/auth";

const dev = {
  aws: {
    Auth: {
      identityPoolId: "us-west-2:845d873c-b4f6-424e-8e1d-d1a8c0ac5d46",
      region: "us-west-2",
      userPoolId: "us-west-2_6mcTE1zPX",
      userPoolWebClientId: "4u5gg7lqiqjhspnrm0u5pacq1b",
    },
    Storage: {
      AWSS3: {
        bucket: "kirs-media-us-west-2-992649602691",
        region: "us-west-2",
        level: "public",
      },
    },
    serviceProviderLogosBucket: "service-provider-logos-us-west-2-992649602691",
  },
  appsync: {
    url:
      "https://4airussapnew3ecuri64ltjklq.appsync-api.us-west-2.amazonaws.com/graphql",
    region: "us-west-2",
    auth: {
      type: "AWS_IAM",
      credentials: () => Auth.currentCredentials(),
    },
  },
  bugsnag: "84f09d1251e71da86c3956fa6f5a329c",
  mixpanel: "1b0b6337143867874a19c313b88733ba",
};

const staging = {
  aws: {
    Auth: {
      identityPoolId: "us-west-2:fb9f2b99-6786-4104-b727-66ccafc36ebc",
      region: "us-west-2",
      userPoolId: "us-west-2_71ZpeIxGe",
      userPoolWebClientId: "5pgktstaedhttunuv2ibuqtgv2",
    },
    Storage: {
      AWSS3: {
        bucket: "kirs-media-us-west-2-119423719352",
        region: "us-west-2",
        level: "public",
      },
    },
    serviceProviderLogosBucket: "service-provider-logos-us-west-2-119423719352",
  },
  appsync: {
    url:
      "https://ls35i3b4e5axperlcxfoguqlhm.appsync-api.us-west-2.amazonaws.com/graphql",
    region: "us-west-2",
    auth: {
      type: "AWS_IAM",
      credentials: () => Auth.currentCredentials(),
    },
  },
  bugsnag: "84f09d1251e71da86c3956fa6f5a329c",
  mixpanel: "7b02fa609690b7d9e1499d152c9f4a64",
};

const prod = {
  aws: {
    Auth: {
      identityPoolId: "us-west-2:394bae17-9605-454b-add3-085e4c73bc01",
      region: "us-west-2",
      userPoolId: "us-west-2_aZ8mMgNAI",
      userPoolWebClientId: "7pumrrlnvf7ukgjh4v4r1cc2tr",
    },
    Storage: {
      AWSS3: {
        bucket: "kirs-media-us-west-2-285716431146",
        region: "us-west-2",
        level: "public",
      },
    },
    serviceProviderLogosBucket: "service-provider-logos-us-west-2-285716431146",
  },
  appsync: {
    url:
      "https://l6kyj37nnfglle3jsdjg5rk5na.appsync-api.us-west-2.amazonaws.com/graphql",
    region: "us-west-2",
    auth: {
      type: "AWS_IAM",
      credentials: () => Auth.currentCredentials(),
    },
  },
  bugsnag: "84f09d1251e71da86c3956fa6f5a329c",
  mixpanel: "d7d5ebc1b886b488ba905e4a057bc668",
};

let config = dev;

// DO NOT CHANGE THESE
if (process.env.REACT_APP_STAGE === "prod") config = prod;
else if (process.env.REACT_APP_STAGE === "staging") config = staging;
else if (process.env.REACT_APP_STAGE === "dev") config = dev;

export default config;
