import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  controlsContainer: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  image: {
    width: "100%",
    transform: ({ rotate }) => `rotate(${rotate}deg)`,
  },
  root: {
    position: "relative",
  },
}));

const RotatableImage = (props) => {
  const [rotate, setRotate] = useState(0);
  const classes = useStyles({ rotate });
  const rotateImage = () => {
    setRotate(rotate === 270 ? 0 : rotate + 90);
  };
  return (
    <div className={classes.root}>
      <img alt="Rotatable" {...props} className={classes.image} />
      <div className={classes.controlsContainer}>
        <Paper>
          <IconButton onClick={rotateImage}>
            <RotateRightIcon />
          </IconButton>
        </Paper>
      </div>
    </div>
  );
};

export default RotatableImage;
