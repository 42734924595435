import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import Separator from "../Separator";
import selectDarkModeEnabled from "../../store/settings/selectors/selectDarkModeEnabled";

const useStyles = makeStyles(() => ({
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
}));

const Error = ({ message, onClick }) => {
  const classes = useStyles();
  const darkModeEnabled = useSelector(selectDarkModeEnabled);
  return (
    <div className={classes.root}>
      <Typography>{message}</Typography>
      <Separator size="medium" />
      <Button
        color={darkModeEnabled ? "inherit" : "primary"}
        onClick={onClick}
        variant="contained"
      >
        Retry
      </Button>
    </div>
  );
};

Error.propTypes = {
  message: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Error;
