import Amplify from "@aws-amplify/core";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import mixpanel from "mixpanel-browser";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./App";
import config from "./config";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import store from "./store";

Amplify.configure(config.aws);

mixpanel.init(config.mixpanel);
mixpanel.set_config({ persistence: "localStorage" });

Bugsnag.start({
  apiKey: config.bugsnag,
  appVersion: process.env.REACT_APP_VERSION,
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
