import { withAuthenticator } from "@aws-amplify/ui-react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import mixpanel from "mixpanel-browser";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Error from "./components/Error";
import ThemedCircularProgress from "./components/ThemedCircularProgress";
import DailyActivityReport from "./pages/DailyActivityReport";
import Home from "./pages/Home";
import IncidentReport from "./pages/IncidentReport";
import Property from "./pages/Property";
import selectUser from "./store/auth/selectors/selectUser";
import { getUserData } from "./store/global";
import selectError from "./store/data/selectors/selectError";
import selectLoading from "./store/data/selectors/selectLoading";
import selectDarkModeEnabled from "./store/settings/selectors/selectDarkModeEnabled";

const useStyles = makeStyles(() => ({
  contentCenteredContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  loadingContainer: {
    height: "100vh",
  },
}));

const App = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dataLoading = useSelector(selectLoading);
  const dataError = useSelector(selectError);
  const darkModeEnabled = useSelector(selectDarkModeEnabled);
  const user = useSelector(selectUser);
  const theme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: darkModeEnabled ? "dark" : "light",
        },
        shape: {
          borderRadius: 8,
        },
      }),
    [darkModeEnabled]
  );
  useEffect(() => {
    dispatch(getUserData());
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    localStorage.setItem("darkModeEnabled", JSON.stringify(darkModeEnabled));
  }, [darkModeEnabled]);
  useEffect(() => {
    if (user) {
      mixpanel.identify(user.id);
      mixpanel.people.set({
        $email: user.email,
        $first_name: user.firstName,
        $last_name: user.lastName,
      });
    }
  }, [user]);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {dataLoading ? (
        <Grid
          alignItems="center"
          className={classes.loadingContainer}
          container
          direction="column"
          justify="center"
        >
          <Grid item>
            <ThemedCircularProgress />
          </Grid>
        </Grid>
      ) : dataError ? (
        <Grid
          alignItems="center"
          className={classes.loadingContainer}
          container
          direction="column"
          justify="center"
        >
          <Grid item>
            <Error
              message="Sorry. An error occurred while loading your data."
              onClick={() => dispatch(getUserData())}
            />
          </Grid>
        </Grid>
      ) : (
        <Router>
          <Switch>
            <Route path="/daily-activity-report/:id">
              <DailyActivityReport />
            </Route>
            <Route path="/incident-report/:id">
              <IncidentReport />
            </Route>
            <Route path="/property/:id">
              <Property />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      )}
    </ThemeProvider>
  );
};

export default withAuthenticator(App);
