import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import BusinessIcon from "@material-ui/icons/Business";
import HomeIcon from "@material-ui/icons/Home";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import selectEntity from "../../store/properties/selectors/selectEntity";

const PropertyListItem = ({ id }) => {
  const property = useSelector((state) => selectEntity(state, id));
  return (
    <ListItem button component={Link} to={`/property/${id}`}>
      <ListItemAvatar>
        <Avatar>
          {property.variant === "COMMERCIAL" ? <BusinessIcon /> : <HomeIcon />}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={property.displayName}
        secondary={property.place.formattedAddress}
      />
    </ListItem>
  );
};

PropertyListItem.propTypes = {
  id: PropTypes.string.isRequired,
};

export default PropertyListItem;
