import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import { getUserData } from "../global";

const adapter = createEntityAdapter({
  selectId: (property) => property.propertyId,
});

const propertiesSlice = createSlice({
  name: "properties",
  initialState: adapter.getInitialState(),
  extraReducers: {
    [getUserData.fulfilled]: (state, action) => {
      const { properties } = action.payload;
      if (Array.isArray(properties)) {
        adapter.setAll(state, properties);
      }
    },
  },
});

const { reducer } = propertiesSlice;

export default reducer;
