import AWSAppSyncClient from "aws-appsync";
import { defaultDataIdFromObject } from "aws-appsync/lib";

import config from "../../config";

const client = new AWSAppSyncClient({
  ...config.appsync,
  cacheOptions: {
    dataIdFromObject: (obj) => {
      switch (obj.__typename) {
        case "IncidentReport":
          return `${obj.__typename}:${obj.incidentId}`;
        case "Patrol":
          return `${obj.__typename}:${obj.patrolId}`;
        default:
          return defaultDataIdFromObject(obj);
      }
    },
  },
  disableOffline: true,
});

export default client;
