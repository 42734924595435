import gql from "graphql-tag";

import serviceProviderFragment from "../../../fragments/serviceProvider";

const getServiceProvider = gql`
  query getServiceProvider($serviceProviderId: ID!) {
    getServiceProvider(serviceProviderId: $serviceProviderId) {
      ...serviceProviderFragment
    }
  }
  ${serviceProviderFragment}
`;

export default getServiceProvider;
