import gql from "graphql-tag";

import incidentReportFragment from "../../../fragments/incidentReport";

const queryApprovedIncidentReports = gql`
  query queryApprovedIncidentReports(
    $propertyId: ID!
    $first: Int!
    $after: ID
  ) {
    queryApprovedIncidentReports(
      propertyId: $propertyId
      first: $first
      after: $after
    ) {
      items {
        ...incidentReportFragment
      }
      nextToken
    }
  }
  ${incidentReportFragment}
`;

export default queryApprovedIncidentReports;
