import { createSelector } from "@reduxjs/toolkit";

import selectData from "../../../data/selectors/selectData";

const selectProperties = createSelector(
  [selectData],
  (data) => data.properties
);

export default selectProperties;
