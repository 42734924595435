import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  darkModeEnabled: JSON.parse(localStorage.getItem("darkModeEnabled")) || false,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    toggleDarkMode: (state) => {
      state.darkModeEnabled = !state.darkModeEnabled;
    },
  },
});

const { actions, reducer } = settingsSlice;

export const { toggleDarkMode } = actions;

export default reducer;
