import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PropTypes from "prop-types";
import React from "react";
import InfiniteScroll from "react-infinite-scroller";

import Error from "../Error";
import Separator from "../Separator";
import ThemedCircularProgress from "../ThemedCircularProgress";

const useStyles = makeStyles(() => ({
  centeredContentContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  paper: {
    overflow: "hidden",
  },
}));

const AsyncInfiniteScrollList = ({
  children,
  error,
  hasMore,
  loading,
  loadMore,
  onRefetch,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  return children.length === 0 ? (
    <Grid container>
      <Grid item xs={12}>
        <Separator size={70} />
      </Grid>
      <Grid item xs={12}>
        {loading ? (
          <div className={classes.centeredContentContainer}>
            <ThemedCircularProgress />
          </div>
        ) : error ? (
          <Error
            message="Sorry. An error occurred while loading your data."
            onClick={onRefetch}
          />
        ) : (
          <div className={classes.centeredContentContainer}>
            <Typography>There is nothing to show.</Typography>
          </div>
        )}
      </Grid>
    </Grid>
  ) : (
    <InfiniteScroll hasMore={hasMore} loadMore={loadMore}>
      <Separator size="medium" />
      <Paper className={classes.paper} square={!matches}>
        {children}
      </Paper>
      {loading && (
        <>
          <Separator size="medium" />
          <div className={classes.centeredContentContainer}>
            <ThemedCircularProgress />
          </div>
        </>
      )}
      <Separator size="medium" />
    </InfiniteScroll>
  );
};

AsyncInfiniteScrollList.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hasMore: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  onRefetch: PropTypes.func.isRequired,
};

AsyncInfiniteScrollList.defaultProps = {
  error: null,
};

export default AsyncInfiniteScrollList;
