import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useMediaQuery } from "@material-ui/core";
import moment from "moment-timezone";
import React, { useCallback, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";

import getPatrol from "../../api/functions/getPatrol";
import DefaultAppBar from "../../components/DefaultAppBar";
import Error from "../../components/Error";
import MediaImage from "../../components/MediaImage";
import Separator from "../../components/Separator";
import ThemedCircularProgress from "../../components/ThemedCircularProgress";
import momentFormats from "../../constants/momentFormats";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
  },
  contentCenteredContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  paper: {
    overflow: "hidden",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
}));

const DailyActivityReport = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const {
    params: { id },
  } = match;
  const [data, setData] = useState({
    loading: false,
    error: null,
    patrol: null,
  });
  const runGetPatrol = useCallback(async () => {
    setData({
      loading: true,
      error: null,
      patrol: null,
    });
    try {
      const patrol = await getPatrol(id);
      setData((prevState) => ({
        ...prevState,
        loading: false,
        patrol,
      }));
    } catch (error) {
      setData((prevState) => ({ ...prevState, loading: false, error }));
    }
  }, [id]);
  useEffect(() => {
    runGetPatrol();
  }, [runGetPatrol]);
  const { loading, error, patrol } = data;
  return (
    <div className={classes.root}>
      <DefaultAppBar title="Daily Activity Report" />
      <Container
        className={classes.container}
        disableGutters={!matches}
        maxWidth="sm"
      >
        {loading ? (
          <>
            <Separator size={70} />
            <div className={classes.contentCenteredContainer}>
              <ThemedCircularProgress />
            </div>
          </>
        ) : error ? (
          <>
            <Separator size={70} />
            <Error
              message="Sorry. An error occurred while loading your data."
              onClick={runGetPatrol}
            />
          </>
        ) : patrol ? (
          <>
            <Separator size="medium" />
            <Paper className={classes.paper} square={!matches}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Separator />
                  <Typography color="textSecondary" variant="h6">
                    General
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Started at"
                    value={moment(patrol.startTime).format(
                      momentFormats.ampmDateTimeFormat
                    )}
                    variant="filled"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Ended at"
                    value={moment(patrol.endTime).format(
                      momentFormats.ampmDateTimeFormat
                    )}
                    variant="filled"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Report ID"
                    value={patrol.patrolId}
                    variant="filled"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Notes"
                    multiline
                    value={patrol.notes}
                    variant="filled"
                  />
                </Grid>
                <Grid item xs={12} />
              </Grid>
            </Paper>
            {Array.isArray(patrol.attachedMedia) &&
              patrol.attachedMedia.length > 0 && (
                <>
                  <Separator size="medium" />
                  <Paper className={classes.paper} square={!matches}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Separator />
                        <Typography color="textSecondary" variant="h6">
                          Media
                        </Typography>
                      </Grid>
                      {patrol.attachedMedia.map((media, index) => (
                        <React.Fragment key={media.mediaId}>
                          {index > 0 && (
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <MediaImage media={media} />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Created at"
                              value={moment(media.createdAt).format(
                                momentFormats.ampmDateTimeFormat
                              )}
                              variant="filled"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              InputProps={{
                                readOnly: true,
                              }}
                              label="Description"
                              multiline
                              value={media.description}
                              variant="filled"
                            />
                          </Grid>
                        </React.Fragment>
                      ))}
                      <Grid item xs={12} />
                    </Grid>
                  </Paper>
                </>
              )}
            <Separator size="medium" />
          </>
        ) : (
          <div />
        )}
      </Container>
    </div>
  );
};

export default DailyActivityReport;
