import gql from "graphql-tag";

import dailyActivityReportFragment from "../../../fragments/dailyActivityReport";

const getPatrol = gql`
  query getPatrol($patrolId: ID) {
    getPatrol(patrolId: $patrolId) {
      ...dailyActivityReportFragment
    }
  }
  ${dailyActivityReportFragment}
`;

export default getPatrol;
