import gql from "graphql-tag";

const serviceProviderFragment = gql`
  fragment serviceProviderFragment on ServiceProvider {
    fullName
    serviceProviderId
    logoPath
  }
`;

export default serviceProviderFragment;
