import Auth from "@aws-amplify/auth";
import { createAsyncThunk } from "@reduxjs/toolkit";

import getClient from "../../api/functions/getClient";
import getProperty from "../../api/functions/getProperty";
import getServiceProvider from "../../api/functions/getServiceProvider";

export const getUserData = createAsyncThunk(
  "data/getUserData",
  async (_, thunkAPI) => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const client = await getClient(cognitoUser.attributes.sub);
    const serviceProvider = await getServiceProvider(client.serviceProviderId);
    const promiseArray = [];
    for (const propertyId of client.propertyIds) {
      promiseArray.push(getProperty(propertyId));
    }
    const properties = await Promise.all(promiseArray);
    return {
      properties,
      serviceProvider: {
        fullName: serviceProvider.fullName,
        logoPath: serviceProvider.logoPath,
        serviceProviderId: serviceProvider.serviceProviderId,
      },
      user: client,
    };
  }
);
