import query from "./graphql";
import client from "../../client";
import generateErrorMessage from "../../utils/generateErrorMessage";

const getPatrol = async (id) => {
  try {
    const { data } = await client.query({
      query,
      variables: {
        patrolId: id,
      },
    });
    return data.getPatrol;
  } catch (error) {
    throw generateErrorMessage("getPatrol", error);
  }
};

export default getPatrol;
