import { combineReducers, configureStore } from "@reduxjs/toolkit";

import authReducer from "./auth";
import dataReducer from "./data";
import settingsReducer from "./settings";

const rootReducer = combineReducers({
  auth: authReducer,
  data: dataReducer,
  settings: settingsReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
