export const statuses = {
  ABANDONED: {
    text: "Abandoned",
    value: "ABANDONED",
  },
  BURNED: {
    text: "Burned",
    value: "BURNED",
  },
  DAMAGED_VANDALIZED: {
    text: "Damaged/Vandalized",
    value: "DAMAGED_VANDALIZED",
  },
  RECLAIMED: {
    text: "Reclaimed",
    value: "RECLAIMED",
  },
  REPOSSESSED: {
    text: "Repossessed",
    value: "REPOSSESSED",
  },
  SEIZED: {
    text: "Seized",
    value: "SEIZED",
  },
  STOLEN: {
    text: "Stolen",
    value: "STOLEN",
  },
  SUSPECT_VEHICLE: {
    text: "Suspect vehicle",
    value: "SUSPECT_VEHICLE",
  },
  VICTIM_VEHICLE: {
    text: "Victim vehicle",
    value: "VICTIM_VEHICLE",
  },
  OTHER: {
    text: "Other",
    value: "OTHER",
  },
};

export const types = {
  AIRPLANE: {
    text: "Airplane",
    value: "AIRPLANE",
  },
  AUTOMOBILE: {
    text: "Automobile",
    value: "AUTOMOBILE",
  },
  BUS: {
    text: "Bus",
    value: "BUS",
  },
  MOTOR_HOME: {
    text: "Motor home",
    value: "MOTOR_HOME",
  },
  TRUCK: {
    text: "Truck",
    value: "TRUCK",
  },
  OTHER: {
    text: "Other",
    value: "OTHER",
  },
};
