import { createSelector } from "@reduxjs/toolkit";

import selectSettings from "../selectSettings";

const selectDarkModeEnabled = createSelector(
  [selectSettings],
  (settings) => settings.darkModeEnabled
);

export default selectDarkModeEnabled;
