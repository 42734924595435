const generateErrorMessage = (functionName, errors) => {
  if (process.env.REACT_APP_STAGE !== "prod") {
    console.warn(functionName, errors);
  }
  if (Array.isArray(errors) && errors.length > 0) {
    return errors[0].message;
  }
  return "An unexpected error occurred";
};

export default generateErrorMessage;
