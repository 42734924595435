import query from "./graphql";
import client from "../../client";
import generateErrorMessage from "../../utils/generateErrorMessage";

const getIncidentReport = async (id) => {
  try {
    const { data } = await client.query({
      query,
      variables: {
        incidentId: id,
      },
    });
    return data.getIncidentReport;
  } catch (errors) {
    throw generateErrorMessage("getIncidentReport", errors);
  }
};

export default getIncidentReport;
