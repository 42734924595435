import gql from "graphql-tag";

import dailyActivityReportFragment from "../../../fragments/dailyActivityReport";

const queryApprovedDailyActivityReports = gql`
  query queryApprovedDailyActivityReports(
    $propertyId: ID!
    $first: Int!
    $after: ID
  ) {
    queryApprovedDailyActivityReports(
      propertyId: $propertyId
      first: $first
      after: $after
    ) {
      items {
        ...dailyActivityReportFragment
      }
      nextToken
    }
  }
  ${dailyActivityReportFragment}
`;

export default queryApprovedDailyActivityReports;
