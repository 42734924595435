import { createSlice } from "@reduxjs/toolkit";

import { getUserData } from "../global";

const initialState = {
  user: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [getUserData.fulfilled]: (state, action) => {
      const { user } = action.payload;
      state.user = user;
    },
  },
});

const { reducer } = authSlice;

export default reducer;
