import gql from "graphql-tag";

import propertyFragment from "../../../fragments/property";

const getProperty = gql`
  query getProperty($propertyId: String!) {
    getProperty(propertyId: $propertyId) {
      ...propertyFragment
    }
  }
  ${propertyFragment}
`;

export default getProperty;
