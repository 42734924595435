import { createReducer } from "@reduxjs/toolkit";

import { getUserData } from "../global";

const initialState = null;

const serviceProviderReducer = createReducer(initialState, {
  [getUserData.fulfilled]: (state, action) => {
    const { serviceProvider } = action.payload;
    return serviceProvider;
  },
});

export default serviceProviderReducer;
