import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import momentFormats from "../../constants/momentFormats";

const IncidentReportListItem = ({ incidentReport }) => {
  return (
    <ListItem
      button
      component={Link}
      to={`/incident-report/${incidentReport.incidentId}`}
    >
      <ListItemText
        primary={moment(incidentReport.startTime).format(
          momentFormats.ampmDateTimeFormat
        )}
      />
    </ListItem>
  );
};

IncidentReportListItem.propTypes = {
  incidentReport: PropTypes.shape({
    createdAt: PropTypes.string,
  }).isRequired,
};

export default IncidentReportListItem;
