import query from "./graphql";
import client from "../../client";
import generateErrorMessage from "../../utils/generateErrorMessage";

const getServiceProvider = async (id) => {
  try {
    const { data } = await client.query({
      query,
      variables: {
        serviceProviderId: id,
      },
      fetchPolicy: "no-cache",
    });
    return data.getServiceProvider;
  } catch (errors) {
    throw generateErrorMessage("getServiceProvider", errors);
  }
};

export default getServiceProvider;
