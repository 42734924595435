import Container from "@material-ui/core/Container";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import DailyActivityReportList from "../../components/DailyActivityReportList";
import DefaultAppBar from "../../components/DefaultAppBar";
import IncidentReportList from "../../components/IncidentReportList";
import TabPanel from "../../components/TabPanel";
import selectEntity from "../../store/properties/selectors/selectEntity";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
  },
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
}));

const Property = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const {
    params: { id },
  } = match;
  const property = useSelector((state) => selectEntity(state, id));
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <div className={classes.root}>
      <DefaultAppBar title={property.displayName}>
        <Tabs
          centered
          onChange={(_, newValue) => setSelectedTab(newValue)}
          value={selectedTab}
          variant={matches ? "standard" : "fullWidth"}
        >
          <Tab label="Daily Activity Reports" />
          <Tab label="Incident Reports" />
        </Tabs>
      </DefaultAppBar>
      <Container
        className={classes.container}
        disableGutters={!matches}
        maxWidth="sm"
      >
        <TabPanel index={0} value={selectedTab}>
          <DailyActivityReportList propertyId={id} />
        </TabPanel>
        <TabPanel index={1} value={selectedTab}>
          <IncidentReportList propertyId={id} />
        </TabPanel>
      </Container>
    </div>
  );
};

export default Property;
