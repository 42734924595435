import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";

const sizes = {
  small: 8,
  medium: 16,
  large: 24,
};

const useStyles = makeStyles(() => ({
  separator: ({ horizontal, size }) => {
    const value = sizes[size] || size;
    return horizontal ? { width: value } : { height: value };
  },
}));

const Separator = ({ horizontal, size }) => {
  const classes = useStyles({ horizontal, size });
  return <div className={classes.separator} />;
};

Separator.propTypes = {
  horizontal: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Separator.defaultProps = {
  horizontal: false,
  size: "small",
};

export default Separator;
