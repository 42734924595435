import { createSelector } from "@reduxjs/toolkit";

import selectData from "../../../data/selectors/selectData";

const selectServiceProvider = createSelector(
  [selectData],
  (data) => data.serviceProvider
);

export default selectServiceProvider;
