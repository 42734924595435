import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import momentFormats from "../../constants/momentFormats";

const DailyActivityReportListItem = ({ dailyActivityReport }) => {
  return (
    <ListItem
      button
      component={Link}
      to={`/daily-activity-report/${dailyActivityReport.patrolId}`}
    >
      <ListItemText
        primary={moment(dailyActivityReport.startTime).format(
          momentFormats.ampmDateTimeFormat
        )}
      />
    </ListItem>
  );
};

DailyActivityReportListItem.propTypes = {
  dailyActivityReport: PropTypes.shape({
    patrolId: PropTypes.string,
    senderId: PropTypes.string,
    startTime: PropTypes.number,
  }).isRequired,
};

export default DailyActivityReportListItem;
