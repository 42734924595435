import gql from "graphql-tag";

import incidentReportFragment from "../../../fragments/incidentReport";

const getIncidentReport = gql`
  query getIncidentReport($incidentId: ID!) {
    getIncidentReport(incidentId: $incidentId) {
      ...incidentReportFragment
    }
  }
  ${incidentReportFragment}
`;

export default getIncidentReport;
