import Storage from "@aws-amplify/storage";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import RotatableImage from "../RotatableImage";

const MediaImage = ({ media }) => {
  const [data, setData] = useState({
    loading: false,
    error: null,
    url: null,
  });
  useEffect(() => {
    (async () => {
      setData({ loading: false, error: null, url: null });
      try {
        const url = await Storage.get(media.mediaId);
        setData((prevState) => ({
          ...prevState,
          loading: false,
          url,
        }));
      } catch (error) {
        setData((prevState) => ({ ...prevState, loading: false, error }));
      }
    })();
  }, [media.mediaId]);
  return (
    data.url && (
      <RotatableImage
        alt={media.id}
        onError={(error) => setData((prevState) => ({ ...prevState, error }))}
        src={data.url}
      />
    )
  );
};

MediaImage.propTypes = {
  media: PropTypes.shape({}).isRequired,
};

export default MediaImage;
