import { createSelector } from "@reduxjs/toolkit";

import selectProperties from "../selectProperties";

const selectEntities = createSelector(
  [selectProperties],
  (properties) => properties.entities
);

export default selectEntities;
