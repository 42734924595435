import query from "./graphql";
import client from "../../client";
import generateErrorMessage from "../../utils/generateErrorMessage";

const getClient = async (id) => {
  try {
    const { data } = await client.query({
      query,
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
    });
    return data.getClient;
  } catch (errors) {
    throw generateErrorMessage("getClient", errors);
  }
};

export default getClient;
