import { combineReducers, createReducer } from "@reduxjs/toolkit";

import dailyActivityReportReducer from "../dailyActivityReports";
import { getUserData } from "../global";
import incidentReportsReducer from "../incidentReports";
import propertiesReducer from "../properties";
import serviceProviderReducer from "../serviceProvider";

const dataLoadingReducer = createReducer(true, {
  [getUserData.pending]: () => true,
  [getUserData.fulfilled]: () => false,
  [getUserData.rejected]: () => false,
});

const dataErrorReducer = createReducer(null, {
  [getUserData.pending]: () => null,
  [getUserData.fulfilled]: () => null,
  [getUserData.rejected]: (state, action) => {
    const { error } = action;
    return error;
  },
});

const dataReducer = combineReducers({
  dailyActivityReports: dailyActivityReportReducer,
  error: dataErrorReducer,
  incidentReports: incidentReportsReducer,
  loading: dataLoadingReducer,
  properties: propertiesReducer,
  serviceProvider: serviceProviderReducer,
});

export default dataReducer;
