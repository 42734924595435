import gql from "graphql-tag";

const dailyActivityReportFragment = gql`
  fragment dailyActivityReportFragment on Patrol {
    displayName
    senderId
    startCoordinates {
      lat
      lng
    }
    endCoordinates {
      lat
      lng
    }
    notes
    startTime
    endTime
    propertyId
    serviceProviderId
    patrolId
    isCardinal
    formattedAddress
    attachedMedia {
      mediaId
      description
      createdAt
    }
  }
`;

export default dailyActivityReportFragment;
