import gql from "graphql-tag";

const incidentReportFragment = gql`
  fragment incidentReportFragment on IncidentReport {
    incidentId
    propertyId
    serviceProviderId
    connectedInstance
    submitted {
      at
      by
    }
    edited {
      at
      by
    }
    startTime
    endTime
    title
    description
    policeCase {
      number
      agency
    }
    subjects {
      name
      sex
      ethnicity
      race
      phoneNumber
      address
      driverLicense {
        state
        number
      }
      description
      type
      height
      weight
      dateOfBirth
      age
      hairColor
      eyeColor
    }
    vehicles {
      description
      make
      model
      year
      color
      vehicleLicense {
        state
        number
      }
      vin
      registeredOwner
      type
      status
    }
    attachedMedia {
      mediaId
      description
      createdAt
    }
    updatedAt
    isDeleted
  }
`;

export default incidentReportFragment;
