import Divider from "@material-ui/core/Divider";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";

import AsyncInfiniteScrollList from "../AsyncInfiniteScrollList";
import DailyActivityReportListItem from "../DailyActivityReportListItem";
import watchQueryApprovedDailyActivityReports from "../../api/functions/watchQueryApprovedDailyActivityReports";

const fetchSize = 20;

const DailyActivityReportList = ({ propertyId }) => {
  const [data, setData] = useState({
    loading: true,
    error: null,
    dailyActivityReports: [],
    nextToken: null,
  });
  const observableQuery = useMemo(
    () =>
      watchQueryApprovedDailyActivityReports({
        propertyId,
        first: fetchSize,
      }),
    [propertyId]
  );
  useEffect(() => {
    const subscription = observableQuery.subscribe(
      ({ data, loading }) => {
        const { items, nextToken } = data.queryApprovedDailyActivityReports;
        setData((prevState) => ({
          ...prevState,
          loading,
          dailyActivityReports: items,
          nextToken,
        }));
      },
      (error) =>
        setData((prevState) => ({ ...prevState, loading: false, error }))
    );
    return () => {
      subscription.unsubscribe();
    };
  }, [observableQuery]);
  const handleLoadMore = () => {
    observableQuery.fetchMore({
      variables: {
        propertyId,
        first: fetchSize,
        after: data.nextToken,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const {
          queryApprovedDailyActivityReports: { items, nextToken },
        } = fetchMoreResult;
        return {
          ...previousResult,
          queryApprovedDailyActivityReports: {
            ...previousResult.queryApprovedDailyActivityReports,
            items: [
              ...previousResult.queryApprovedDailyActivityReports.items,
              ...items,
            ],
            nextToken,
          },
        };
      },
    });
  };
  const handleRefetch = async () => {
    setData((prevState) => ({ ...prevState, loading: true, error: null }));
    try {
      await observableQuery.refetch();
    } catch (error) {
      setData((prevState) => ({ ...prevState, loading: false, error }));
    }
  };
  return (
    <AsyncInfiniteScrollList
      error={data.error}
      hasMore={Boolean(data.nextToken)}
      loading={data.loading}
      loadMore={handleLoadMore}
      onRefetch={handleRefetch}
    >
      {data.dailyActivityReports.map((dailyActivityReport, index) => (
        <div key={dailyActivityReport.patrolId}>
          {index !== 0 && <Divider />}
          <DailyActivityReportListItem
            dailyActivityReport={dailyActivityReport}
          />
        </div>
      ))}
    </AsyncInfiniteScrollList>
  );
};

DailyActivityReportList.propTypes = {
  propertyId: PropTypes.string.isRequired,
};

export default DailyActivityReportList;
