import gql from "graphql-tag";

const clientFragment = gql`
  fragment clientFragment on Client {
    id
    serviceProviderId
    updatedAt
    propertyIds
    firstName
    lastName
    email
    isArchived
    isDeleted
  }
`;

export default clientFragment;
